import { useNewsroomContext } from '@prezly/theme-kit-nextjs';

import type { ThemeSettings, ThemeSettingsApiResponse } from 'types';

import { DEFAULT_THEME_SETTINGS } from './constants';

export function useThemeSettings(): ThemeSettings {
    const { themePreset } = useNewsroomContext();

    const settings: ThemeSettingsApiResponse = {
        ...DEFAULT_THEME_SETTINGS,
        ...themePreset?.settings,
    };

    return {
        accentColor: settings.accent_color,
        font: settings.font,
        headerBackgroundColor: settings.header_background_color,
        headerLinkColor: settings.header_link_color,
        showDate: settings.show_date,
        showSubtitle: settings.show_subtitle,
    };
}
